<template>
  <h6 class="softwarehead">
    <ICON_Tag :size="icon_size" :stop_color1="start_color" />{{ saint_text }}
  </h6>
</template>
<script>
import ICON_Tag from "@/assets/icons/Tag.vue";
export default {
  components: {
    ICON_Tag,
  },
  data() {
    return {
      icon_size: 18,
      start_color: "grey",
    };
  },
  props: {
    saint_text: {
      type: String,
      default: "Saint 1",
    },
  },
};
</script>
<style>
h6.softwarehead {
  font-weight: 700;
  margin: 15px 0px;
  font-size: 16px;
  border: 2px solid #dadcdf;
  border-radius: 10px;
  padding: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.softwarehead svg {
  margin: 0 10px 0 0;
}
</style>
