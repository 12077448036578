<template>
  <section id="about" class="about">
    <div class="container">
      <div class="row">
        <div class="col-md-7 aboutsection">
          <div class="col-md-12 col-12 pb-2">
            <h2>Mass Reading</h2>
            <p>Date: 19/05/2022</p>
            <hr />
          </div>
          <div class="section-title">
            <p>
              There was an attempt in Iconium by both the Gentiles and the Jews,
              together with their leaders, to attack and stone Paul and
              Barnabas. They realized it, and fled to the Lycaonian cities of
              Lystra and Derbe and to the surrounding countryside, where they
              continued to proclaim the Good News.
            </p>
            <Tag />
            <p>
              At Lystra there was a crippled man, lame from birth, who had never
              walked. He listened to Paul speaking, who looked intently at him,
              saw that he had the faith to be healed, and called out in a loud
              voice, “Stand up straight on your feet.” He jumped up and began to
              walk about. When the crowds saw what Paul had done, they cried out
              in Lycaonian, “The gods have come down to us in human form.” They
              called Barnabas “Zeus” and Paul “Hermes,” because he was the chief
              speaker. And the priest of Zeus, whose temple was at the entrance
              to the city, brought oxen and garlands to the gates, for he
              together with the people intended to offer sacrifice.
            </p>
            <Tag :saint_text="saint_of_the_day" />
            <p>
              The Apostles Barnabas and Paul tore their garments when they heard
              this and rushed out into the crowd, shouting, “Men, why are you
              doing this? We are of the same nature as you, human beings. We
              proclaim to you good news that you should turn from these idols to
              the living God, who made heaven and earth and sea and all that is
              in them. In past generations he allowed all Gentiles to go their
              own ways; yet, in bestowing his goodness, he did not leave himself
              without witness, for he gave you rains from heaven and fruitful
              seasons, and filled you with nourishment and gladness for your
              hearts.” Even with these words, they scarcely restrained the
              crowds from offering sacrifice to them.
            </p>
          </div>
        </div>
        <ContentByFilter />
      </div>
    </div>
  </section>
</template>

<script>
import Tag from "@/components/Tag.vue";
import ContentByFilter from "@/components/ContentByFilter.vue";
export default {
  components: {
    ContentByFilter,
    Tag,
  },
  data() {
    return {
      saint_of_the_day: "Saint 2",
    };
  },
};
</script>

<style scoped>
select.selectpicker {
  padding: 5px 10px;
  font-size: 16px;
}
.about {
  padding: 30px;
  background: #f9f9f9;
  position: relative;
}
.aboutsection {
  padding: 30px;
}
.about:before {
  content: "";
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

.about .container {
  position: relative;
}

.about .content h2 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 26px;
}

@media (max-width: 992px) {
  .about {
    padding: 30px;
  }
}
</style>
